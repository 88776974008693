






















import { Component, Vue } from 'vue-property-decorator'
import Main from '@/layouts/Main.vue'
import app from '@/store/modules/app'
import OurStoryEvent from '@/components/OurStoryEvent.vue'
import { OurStoryTimelineEvent } from '@/models/OurStory'

@Component({
  components: { Main, OurStoryEvent },
  metaInfo() {
    return {
      title: `${app.title} | Our Story`,
      meta: [
        {
          name: 'description',
          content:
            'The story of Katie and Erik, from their first meeting to the wedding day.',
        },
        {
          name: 'keywords',
          content: 'Our Story, Katie and Erik, Love Story, Wedding, Timeline',
        },
        // Open Graph / Facebook
        { property: 'og:type', content: 'website' },
        { property: 'og:url', content: 'https://themadays.com/our-story' },
        { property: 'og:title', content: 'Our Story - Katie & Erik' },
        {
          property: 'og:description',
          content:
            'The story of Katie and Erik, from their first meeting to the wedding day.',
        },
        // Twitter
        { name: 'twitter:card', content: 'summary_large_image' },
        { name: 'twitter:url', content: 'https://themadays.com/our-story' },
        { name: 'twitter:title', content: 'Our Story - Katie & Erik' },
        {
          name: 'twitter:description',
          content:
            'The story of Katie and Erik, from their first meeting to the wedding day.',
        },
      ],
      link: [{ rel: 'canonical', href: 'https://themadays.com/our-story' }],
    }
  },
})
export default class OurStory extends Vue {
  // add images property to all objects missing it in timeline

  timeline: OurStoryTimelineEvent[] = [
    {
      date: 'May 24, 2018',
      text: 'Katie and Erik matched on Bumble. Erik doesn’t remember swiping on Katie. Katie only swiped on Erik because he said he was 6’6” - tall enough for Katie to wear heels. Katie sent Erik this riveting and high-effort conversation starter: hey Erik! After Erik deemed Katie cute enough to respond to this terrible opener, the rest was history! (Sorry family – I know we told many of you we met at church. Here’s the real story!)',
      image: 'bumble.png',
    },
    {
      date: 'June 3, 2018',
      text: 'Katie and Erik had their first date at a coffee shop in Atlanta. They got to talking, lost track of time, missed dinner, and got kicked out at closing time. Katie hasn’t stopped talking since.',
      image: 'first-date.png',
    },
    {
      date: 'July 4, 2018',
      text: 'After a month full of hikes, kayaking, stargazing, and exploring the city, Katie and Erik decided they would be girlfriend / boyfriend, even if it meant they were signing up for 2 years of long-distance from Atlanta to New Orleans.',
      image: 'making-it-official.png',
    },
    {
      date: '2018-2020',
      text: 'Katie and Erik fell in love as they navigated long-distance between their two cities. Erik came to Katie’s Tulane games to cheer on her dance team. Katie came to Erik’s Georgia Tech basketball games to cheer him on as a Graduate Assistant. They even spent a few holidays and summers together.',
      image: 'distance.png',
    },
    {
      date: '2020',
      text: 'Thanks to the pandemic, Katie and Erik went from long distance to living together in mere months (shout out Brandon & McKenzie for being the best first roommates!). They started their new lives and careers together in Atlanta.',
      image: 'moving-in.png',
    },
    {
      date: 'May 22, 2022',
      text: 'Erik proposed to Katie after almost 4 years together! Erik truly outdid himself, putting up photos and keepsakes around the frame of a barn and even getting their favorite artist to custom record a love song for them. It took some convincing, but Katie eventually said yes!',
      image: 'proposal.png',
    },
    {
      date: 'June 5, 2022',
      text: 'Katie and Erik packed a U-Haul and moved to Salt Lake City, Utah for Katie’s job. They have been living their best outdoors life ever since, hiking every summer weekend and skiing every winter weekend.',
      image: 'moving.png',
    },
    {
      date: 'September 30, 2023',
      text: 'Katie and Erik are getting married! They can’t wait to have friends and family in Rockmart, Georgia for their special day. ',
      image: 'getting-hitched.png',
    },
  ]
}
