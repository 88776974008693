




















import { OurStoryTimelineEvent } from '@/models/OurStory'
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({})
export default class OurStoryEvent extends Vue {
  @Prop({ type: Object, required: true }) readonly event: OurStoryTimelineEvent

  get image(): string {
    try {
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      return require(`@/assets/images/timeline/${this.event.image}`)
    } catch (e) {
      console.error(e)
    }
    return null
  }
}
